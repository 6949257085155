import React from "react";

import { graphql } from "gatsby";

import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Hero from "components/Hero";
import PageContainer from "components/PageContainer";
import RequestDemoForm from "components/RequestDemoForm";
import SEO from "components/SEO";

const DemoPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Hero
        title={t("Contact Us")}
        subtitle={
          <small>
            <Trans
              i18nKey="contactLink"
              components={{
                Link: (
                  <a href="mailto:contact@qantev.com">contact@qantev.com</a>
                ),
              }}
            />
          </small>
        }
        img={<RequestDemoForm isContact />}
        reverseImg
      />
    </PageContainer>
  );
};

export default DemoPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="Contact" pathname={pathname} />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["demo", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
