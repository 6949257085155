import React, { useState } from "react";

import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import propTypes from "prop-types";
import toast from "react-hot-toast";
import styled from "styled-components";

const FormContainer = styled.form`
  padding: 24px;
  font-family: Poppins, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormLabel = styled.label`
  display: block;
  font-size: 0.875rem;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;

const FormInput = styled.input`
  color: #00033f;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-weight: 400;

  &:focus {
    outline-color: #12ce9f;
  }
`;

const FormTextarea = styled.textarea`
  color: #00033f;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-weight: 400;

  &:focus {
    outline-color: #12ce9f;
  }
`;

const SubmitButton = styled.button`
  width: 33%;
  background-color: #12ce9f;
  color: #00033f;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: #0fb98d;
  }

  &:focus {
    outline-color: #00033f;
  }
`;

const RequestDemoForm = ({ isContact }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    organizationName: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const data = {
      blocks: [
        {
          type: "section",
          fields: [
            {
              type: "plain_text",
              text: `Name: ${formData.firstName} ${formData.lastName}`,
            },
            {
              type: "plain_text",
              text: `Org: ${formData.organizationName}`,
            },
            {
              type: "plain_text",
              text: `Email: ${formData.email}`,
            },
            {
              type: "plain_text",
              text: `Phone: ${formData.phone}`,
            },
            {
              type: "plain_text",
              text: `Country: ${formData.country}`,
            },
            {
              type: "plain_text",
              text: `Type: ${isContact ? "Contact Form" : "Demo Request"}`,
            },
          ],
        },
        { type: "divider" },
        {
          type: "section",
          text: {
            type: "plain_text",
            text: formData.message,
          },
        },
      ],
    };

    if (!process.env.GATSBY_SLACK_WEBHOOK_URL) {
      throw new Error("Missing contact form URL!");
    }

    return fetch(process.env.GATSBY_SLACK_WEBHOOK_URL, {
      method: "POST",
      body: JSON.stringify(data),
    });
  };

  const submitWithToast = (e) => {
    e.preventDefault();

    toast
      .promise(handleSubmit(), {
        loading: t("Submitting form..."),
        success: t("Form submitted successfully!"),
        error: (
          <span>
            <Trans
              t={t}
              i18nKey="Form submission failed! Please disable your adblocker and try again, or email us at <Link>contact@qantev.com</Link>."
              components={{
                Link: (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  <a
                    style={{ fontSize: "0.875rem", color: "#12ce9f" }}
                    href="mailto:contact@qantev.com"
                  />
                ),
              }}
            />
          </span>
        ),
      })
      .then(() => {
        setFormData({
          firstName: "",
          lastName: "",
          organizationName: "",
          email: "",
          phone: "",
          country: "",
          message: "",
        });
      });
  };
  return (
    <FormContainer onSubmit={submitWithToast}>
      <InputWrapper>
        <FormLabel htmlFor="firstName">
          {t("First name")}
          <FormInput
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </FormLabel>
        <FormLabel htmlFor="lastName">
          {t("Last name")}
          <FormInput
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </FormLabel>
      </InputWrapper>

      <FormLabel htmlFor="organizationName">
        {t("Organization")}
        <FormInput
          type="text"
          id="organizationName"
          name="organizationName"
          value={formData.organizationName}
          onChange={handleChange}
          required
        />
      </FormLabel>

      <FormLabel htmlFor="email">
        {t("Email")}
        <FormInput
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </FormLabel>

      <FormLabel htmlFor="phone">
        {t("Phone number")}
        <FormInput
          type="tel"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </FormLabel>

      <FormLabel htmlFor="country">
        {t("Country")}
        <FormInput
          type="text"
          id="country"
          name="country"
          value={formData.country}
          onChange={handleChange}
          required
        />
      </FormLabel>

      <FormLabel htmlFor="message">
        {isContact
          ? t("Message")
          : t("What would you like to see in your demo?")}
        <FormTextarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        />
      </FormLabel>
      <SubmitButton type="submit">{t("Submit")}</SubmitButton>
    </FormContainer>
  );
};

RequestDemoForm.propTypes = {
  isContact: propTypes.bool,
};

RequestDemoForm.defaultProps = {
  isContact: false,
};

export default RequestDemoForm;
